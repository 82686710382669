import React, {useEffect, useState} from 'react';
import { fetchCleanupStatus, startCleanupStatus } from '../api/api';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

type CleanupOrchestrationProps = {
    tenant: string;
    environment: string;
};

function CleanupOrchestration({ tenant, environment }: CleanupOrchestrationProps) {
    const [cleanupStatus, setCleanupStatus] = useState(null);
    
    const handleStartCleanup = () => {
        startCleanupStatus()
            .then(data => {
                setCleanupStatus(data.status);
            })
    };
    
    useEffect(() => {
        fetchCleanupStatus()
            .then(data => {
                setCleanupStatus(data.status);
            })
    }, []);
    
    return (
        <div>
            <Card>
                <Card.Body>
                    <Card.Title>{tenant && environment && `Tenant: ${tenant} - Environment: ${environment}`}</Card.Title>
                    <Card.Text>
                        Cleanup Orchestration Status: 
                        <Badge bg={cleanupStatus === 'RUNNING' ? 'success' : 'secondary'}>
                            {cleanupStatus || 'Not running'}
                        </Badge>
                    </Card.Text>
                    {cleanupStatus !== "RUNNING" && (
                        <Button onClick={handleStartCleanup}>Start Orchestration</Button>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
}

export default CleanupOrchestration;