import React, {useEffect, useState} from 'react';
import {HashRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loads from './pages/loads';
import {Organization} from "./types";
import RetryLoadComponent from "./pages/loads/RetryLoadComponent";
import AbandonLoadComponent from "./pages/loads/AbandonLoadComponent";
import CurrentLoadsComponent from "./pages/loads/CurrentLoadsComponent";
import CleanupOrchestration from "./pages/CleanupOrchestration";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {fetchEnvironmentName} from './api/api';

function App() {
    const [user, setUser] = useState<any>(null);
    const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
    const [tenant, setTenant] = useState<string | null>(null);
    const [environment, setEnvironment] = useState<string | null>(null);
    const [error, setError] = useState<React.ReactNode | null>(null);

    const handleOrgSelect = (org: Organization | null) => {
        setSelectedOrg(org);
    };

    useEffect(() => {
        fetch("/.auth/me")
            .then((response) => response.json())
            .then((data) => {
                const { clientPrincipal } = data;
                setUser(clientPrincipal);
            })
            .catch((error) => {
                console.error("Error during authentication: ", error);
            })
    }, []);

    useEffect(() => {
        if (user) {
            fetchEnvironmentName()
                .then(response => {
                    if (response.status === 401) {
                        throw new Error("Unauthorized")
                    }
                    setError(null);
                    return response.json();
                })
                .then((data) => {
                    setTenant(data.tenantCode);
                    setEnvironment(data.environment);
                })
                .catch((error) => {
                    console.error("Error during authentication: ", error);

                    if (error.message === "Unauthorized") {
                        setError(
                            <>
                                You are unauthorized. Please ask your admin to give you access to the app.
                                For information on granting access, see <a href="https://learn.microsoft.com/en-us/azure/static-web-apps/authentication-custom?tabs=aad%2Cinvitations#manage-roles" target="_blank" rel="noopener noreferrer">here</a>.
                            </>
                        );
                    } else {
                        setError("Failed to fetch data. Please try again later.");
                    }
                });
        } else {
            setError("Please sign in to view this page.");
        }
    }, [user]);

    return (
        <Router>
            <NavBar user={user}/>
            {error ? (
                <Container>
                    <Row className="justify-content-md-center">
                        <Col>
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : (
                tenant && environment && (
                    <Container>
                        <Row>
                            <Col><CleanupOrchestration tenant={tenant.toUpperCase()} environment={environment.toUpperCase()}/></Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col><CurrentLoadsComponent onOrgSelect={handleOrgSelect} selectedOrg={selectedOrg} /></Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col>
                                <Routes>
                                    <Route path="/organization/:orgName/loads" element={<Loads organization={selectedOrg} onOrgSelect={handleOrgSelect} />} />
                                    <Route path="/organization/:orgName/loads/:loadId/:phase/retry" element={<RetryLoadComponent setSelectedOrg={setSelectedOrg} />} />
                                    <Route path="/organization/:orgName/loads/:loadId/:phase/abandon" element={<AbandonLoadComponent setSelectedOrg={setSelectedOrg} />} />
                                </Routes>
                            </Col>
                        </Row>
                    </Container>
                )
            )}
        </Router>
    );
}

export default App;