import React, {useEffect, useState} from 'react';
import {fetchOrganizations} from '../../api/api';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {Organization} from "../../types";
import {useNavigate} from 'react-router-dom';
import Card from 'react-bootstrap/Card';

type CurrentLoadsProps = {
    onOrgSelect: (org: Organization | null) => void;
    selectedOrg: Organization | null;
};

function CurrentLoadsComponent({onOrgSelect, selectedOrg}: CurrentLoadsProps) {
    const [loads, setLoads] = useState<any[]>([]);
    const [error, setError] = useState<React.ReactNode | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchOrganizations()
            .then(response => {
                if (response.status === 401) {
                    throw new Error("Unauthorized");
                }
                return response.json();
            })
            .then(data => {
                if (!data) {
                    console.error("No data received from the server.");
                    throw new Error("No data");
                }
                setLoads(data);
            })
            .catch(error => {
                console.error("Failed to fetch current loads:", error);

                if (error.message === "Unauthorized") {
                    setError(
                        <>
                            You are unauthorized. Please ask your admin to give you access to the app.
                            For information on granting access, see <a href="https://learn.microsoft.com/en-us/azure/static-web-apps/authentication-custom?tabs=aad%2Cinvitations#manage-roles" target="_blank" rel="noopener noreferrer">here</a>.
                        </>
                    );
                } else {
                    setError("Failed to fetch data. Please try again later.");
                }
            });
    }, []);

    if (error) {
        return <div>{error}</div>
    }

    return (
        <div>
            {selectedOrg ? (
                <div>
                    <Button onClick={() => {
                        onOrgSelect(null);
                        navigate('/');
                    }} style={{ marginTop: '10px' }}>Back</Button>
                    <h2 style={{ fontSize: '24px', marginTop: '10px' }}>Organization: {selectedOrg.name.toUpperCase()}</h2>
                </div>
            ) : (
                <Card style={{ marginTop: '10px' }}>
                    <Card.Header>
                        <h4 style={{ marginTop: '10px' }}>Current Extractions</h4>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Organization</th>
                                <th>Last Extraction</th>
                                <th>Current Extraction</th>
                            </tr>
                            </thead>
                            <tbody>
                                {loads.map(load => (
                                    <tr key={load.entity.entityId.key}>
                                        <td>
                                            <Button size="sm" variant="primary" key={load.entity.entityId.key} onClick={() => {
                                                onOrgSelect({id: load.entity.entityId.key, name: load.organizationCode});
                                                navigate(`/organization/${load.organizationCode}/loads`);}}>
                                                {(load.organizationCode).toUpperCase()}
                                            </Button>
                                        </td>
                                        <td>{new Date(load.entity.lastOperationTime).toLocaleString()}</td>
                                        <td>{load.entity.state.currentLoad ? (JSON.parse(load.entity.state.currentLoad).CurrentLoad || "No current load") : "No current load"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            )}
        </div>
    );
}

export default CurrentLoadsComponent;