import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Load, Organization} from '../../types';
import {fetchLoadsForOrg, fetchRunningLoads} from '../../api/api';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {decodeTime} from 'ulid';

type AllLoadsForOrgProps = {
    organization: Organization;
    loadType:'running' | 'completed';
};

function AllLoadsListComponent({organization, loadType }: AllLoadsForOrgProps) {
    const [loads, setLoads] = useState<any[]>([]);
    const navigate = useNavigate();

    const extractTimeFromInstanceId = (instanceId: string) => {
        const parts = instanceId.split(":");
        if (parts.length < 2) {
            console.warn("instanceId does not have the expected format with ':':", instanceId);
            return new Date(0);
        }

        const ulid = parts[1];
        if (!ulid || ulid.length !== 26) {
            console.warn("Unable to extract ULID from instanceId:", instanceId);
            return new Date(0);
        }
        const timestamp = decodeTime(ulid);
        return new Date(timestamp);
    }

    const handleRetryClick = (loadId: string, phase: string) => {
        navigate(`/organization/${organization.name}/loads/${loadId}/${phase}/retry`);
    };

    const handleAbandonClick = (loadId: string, phase: string) => {
        navigate(`/organization/${organization.name}/loads/${loadId}/${phase}/abandon`);
    };

    useEffect(() => {
        const fetchFunction = loadType === 'running' ? fetchRunningLoads : fetchLoadsForOrg;
        fetchFunction(organization.name)
            .then((data: Load[]) => {
                const enrichedData = data.map(load => ({
                    ...load,
                    calculatedDate: extractTimeFromInstanceId(load.instanceId)
                }));
                setLoads(enrichedData);
            })
            .catch(error => {
                console.error(`Error fetching ${loadType} loads:`, error);
            });

    }, [organization, loadType]);

    const sortedLoads = [...loads].sort((a, b) => {
        const dateA = a.calculatedDate;
        const dateB = b.calculatedDate;

        return dateB.getTime() - dateA.getTime();
    });

    const noLoadMessage = loadType === 'completed'
        ? 'No completed loads within the last 30 days'
        : 'No current loads';
    
    return (
        <div style={{ overflow: 'scroll' }}>
            {sortedLoads.length > 0 ? (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Instance ID</th>
                        <th>Phase</th>
                        <th>Status</th>
                        <th>Created Date</th>
                        <th>Error</th>
                        {loadType === 'running' ? <th>Actions</th> : <></>}
                    </tr>
                    </thead>
                    <tbody>
                    {sortedLoads.map(load => (
                        <tr key={load.id}>
                            <td>{load.instanceId}</td>
                            <td>{load.customStatus && load.customStatus.Phase}</td>
                            <td>{load.customStatus && load.customStatus.Status}</td>
                            <td>{`${load.calculatedDate.toLocaleDateString()} ${load.calculatedDate.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit'
                            })}`}</td>
                            {(load.customStatus && load.customStatus.Error) ? <td>{load.customStatus.Error}</td> : <td></td>}
                            {loadType === 'running' ?
                                <td>
                                    {load.customStatus.Status === 'Waiting' &&
                                    (load.customStatus.Phase === 'Load' || load.customStatus.Phase === 'Transform') ? (
                                        <>
                                            <Button size="sm" variant="primary" onClick={() => handleRetryClick(load.instanceId, load.customStatus.Phase.toLowerCase())}>Retry</Button>
                                            {' '}
                                            <Button size="sm" variant="danger" onClick={() => handleAbandonClick(load.instanceId, load.customStatus.Phase.toLowerCase())}>Abandon</Button>
                                        </>
                                    ) : null
                                    }
                                </td> : <></>
                            }
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <p>{noLoadMessage}</p>
                </div>
            )}
        </div>
    );
}

export default AllLoadsListComponent;