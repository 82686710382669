import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

interface User {
    userDetails: string;
}

interface NavBarProps {
    user: User | null;
}

function NavBar({ user }: NavBarProps) {

    return (
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand href="/">Power BI Ops Dashboard</Navbar.Brand>
                    <Nav className="ml-auto">
                        {user ? (
                            <>
                                <Nav.Item className="navbar-text">{user.userDetails}</Nav.Item>
                                <Nav.Link href="/.auth/logout">Sign Out</Nav.Link>
                            </>
                        ) : (
                            <Nav.Link href="/.auth/login/aad">Sign In</Nav.Link>
                        )}
                    </Nav>
            </Container>
        </Navbar>
    );
}

export default NavBar;