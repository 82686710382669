import React, {useEffect} from 'react';
import { Organization } from '../../types';
import AllLoadsListComponent from './AllLoadsListComponent';
import RunTransformAllComponent from '../RunTransformAllComponent';
import {useParams} from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

type LoadsProps = {
    organization: Organization | null;
    onOrgSelect: (org: Organization | null) => void;
};

function Loads({ organization, onOrgSelect }: LoadsProps) {
    const { orgName } = useParams<{ orgName?: string }>();

    useEffect(() => {
        if (orgName && (!organization || organization.name !== orgName)) {
            onOrgSelect({ id: "", name: orgName });
        }
    }, [orgName, onOrgSelect, organization]);

    return (
        <Card>
            <Card.Header>
                {organization && <RunTransformAllComponent organization={organization}/>}
            </Card.Header>
            <Card.Body>
                <Tabs defaultActiveKey="current" id="uncontrolled-tab-example">
                    <Tab eventKey="current" title="Running Extractions">
                        {organization && <AllLoadsListComponent organization={organization} loadType="running" />}
                    </Tab>
                    <Tab eventKey="completed" title="Completed Extractions">
                        {organization && <AllLoadsListComponent organization={organization} loadType="completed" />}
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    );
}

export default Loads;
