import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { handleRetry } from '../../api/api';
import {Organization} from "../../types";

type RetryLoadProps = {
    setSelectedOrg: React.Dispatch<React.SetStateAction<Organization | null>>;
};

function RetryLoadComponent({ setSelectedOrg }: RetryLoadProps) {
    const { orgName, loadId, phase } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (loadId && phase) {
            const userConfirmed = window.confirm('Are you sure you want to retry this load?');
            if (userConfirmed) {
                handleRetry(loadId, phase)
                    .then(() => {
                        alert('Load retried successfully!');
                        setSelectedOrg(null);
                        navigate(`/`);
                    })
                    .catch(error => {
                        alert(`Error retrying load: ${error}`);
                        setSelectedOrg(null);
                        navigate(`/`);
                    });
            } else {
                navigate(`/`);
            }
        } else {
            alert('Load ID is missing.');
        }

    }, [loadId, navigate, orgName, phase, setSelectedOrg]);

    return <div>Retrying load...</div>;
}

export default RetryLoadComponent;