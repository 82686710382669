import React, {useEffect, useState} from 'react';
import { startTransformAll, stopTransformAll, fetchTransformStatus, fetchRunningLoads } from '../api/api';
import {Organization, Load} from '../types';
import Button from 'react-bootstrap/Button';
import Table from "react-bootstrap/Table";
import Badge from 'react-bootstrap/Badge';

interface ContainerInfo {
    name: string;
    state: string;
    startOn: string;
    finishOn: string;
    detailStatus: string;
}

interface TransformAllStatus {
    provisioningState: string;
    containers: ContainerInfo[];
    state: string;
    logs: string;
}

type RunTransformAllProps = {
    organization: Organization;
}

function formatDate(dateString: string | null): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
    })}`;
}

function RunTransformAllComponent({organization}: RunTransformAllProps) {
    const [transformAllStatus, setTransformAllStatus] = useState<TransformAllStatus | null>(null)
    const [cantAlterTransform, setCantAlterTransform] = useState(false);

    const handleStartTransformAll = () => {
        const userConfirmed = window.confirm('Are you sure you want to start the transformation process?');
        if (userConfirmed) {
            startTransformAll(organization.name)
                .then(() => fetchTransformStatus(organization.name))
                .then(data => {
                    setTransformAllStatus(data);
                    alert('Transformation process started successfully!');
                })
                .catch(error => {
                    alert(`Error starting transformation: ${error}`);
                });
        }
    };
    
    const handleStopTransformAll = () => {
        const userConfirmed = window.confirm('Are you sure you want to stop the transformation process?');
        if (userConfirmed) {
            stopTransformAll(organization.name)
                .then(() => fetchTransformStatus(organization.name))
                .then(data => {
                    setTransformAllStatus(data);
                    alert('Transformation process stopped successfully!');
                })
                .catch(error => {
                    alert(`Error stopping transformation: ${error}`);
                });
        }
    };

    useEffect(() => {
        fetchTransformStatus(organization.name)
            .then(data => {
                setTransformAllStatus(data)
            })
        fetchRunningLoads(organization.name)
            .then((data: Load[]) => {
                const cantAlterTransform = data.length > 0;
                setCantAlterTransform(cantAlterTransform);
            })
    }, [organization.name]);

    const isContainerRunning = transformAllStatus?.containers?.some(container => container.state === "Running" || container.state === "Waiting");
    
    return (
        <div>
            <h4>Transform</h4>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Container Name</th>
                    <th>State</th>
                    <th>Last Start</th>
                    <th>Last Complete</th>
                    <th>Status Details</th>
                </tr>
                </thead>
                <tbody>
                    {transformAllStatus?.containers?.map((container, index) => (
                        <tr key={index}>
                            <td>{container.name}</td>
                            <td>
                                <Badge bg={transformAllStatus?.state === 'Succeeded' ? 'success' : transformAllStatus?.state === 'Failed' ? 'danger' : 'secondary'}>
                                    {transformAllStatus?.state}
                                </Badge>
                            </td>
                            <td>{formatDate(container.startOn)}</td>
                            <td>{formatDate(container.finishOn)}</td>
                            <td>
                                {container.detailStatus || transformAllStatus?.state !== "Running" ? container.detailStatus : transformAllStatus?.provisioningState}
                            </td>
                        </tr>
                    ))}
                    {isContainerRunning && !cantAlterTransform ? (
                        <Button onClick={handleStopTransformAll}>
                            Stop Full Refresh
                        </Button>
                    ) : !isContainerRunning && !cantAlterTransform ? (
                        <Button onClick={handleStartTransformAll}>
                            Start Full Refresh
                        </Button>
                    ) : null}
                </tbody>
            </Table>
        </div>
    );
}

export default RunTransformAllComponent;