const BASE_URL = '/api';

export const fetchOrganizations = async () => {
    try {
        return await fetch(`${BASE_URL}/organizations`);
    } catch (error) {
        console.error('Error fetching organizations:', error);
        throw error;
    }
};

export const fetchCleanupStatus = async () => {
    try {
        const response = await fetch(`${BASE_URL}/cleanup`, { method: 'GET' });
        return response.json();
    } catch (error) {
        console.error('Error fetching Cleanup Orchestration status:', error);
        throw error;
    }
}

export const startCleanupStatus = async () => {
    try {
        const response = await fetch(`${BASE_URL}/cleanup`, { method: 'POST' });
        return response.json();
    } catch (error) {
        console.error('Error starting Cleanup Orchestration:', error);
        throw error;
    }
}

export const fetchTransformStatus = async (orgName: string) => {
    try {
        const response = await fetch(`${BASE_URL}/transforms/full/${orgName}`, { method: 'GET' });
        return response.json();
    } catch (error) {
        console.error('Error fetching Transform All status:', error);
        throw error;
    }
}

export const startTransformAll = async (orgName: string) => {
    try {
        const response = await fetch(`${BASE_URL}/transforms/full/${orgName}`, { method: 'POST' });
        return response.json();
    } catch (error) {
        console.error('Error starting Transform All Store Procedure:', error);
        throw error;
    }
}

export const stopTransformAll = async (orgName: string) => {
    try {
        const response = await fetch(`${BASE_URL}/transforms/full/${orgName}`, { method: 'PUT'});
        return response.json();
    } catch (error) {
        console.error('Error stoping Transform All Store Procedure:', error);
        throw error;
    }
}

export const fetchEnvironmentName = async () => {
    try {
        return await fetch(`${BASE_URL}/environment`);
    } catch (error) {
        console.error('Error fetching environment name:', error);
        throw error;
    }
};

export const fetchRunningLoads = async (orgName: string) => {
    try {
        const response = await fetch(`${BASE_URL}/organizations/${orgName}/loads/running`);
        return response.json();
    } catch (error) {
        console.error('Error fetching running loads.', error);
        throw error;
    }
};

export const fetchLoadsForOrg = async (orgName: string) => {
    try {
        const response = await fetch(`${BASE_URL}/organizations/${orgName}/loads`);
        return response.json();
    } catch (error) {
        console.error(`Error fetching loads for organization ${orgName}:`, error);
        throw error;
    }
};

export const handleAbandon = async (loadId: string, phase: string) => {
    try {
        const response = await fetch(`${BASE_URL}/loads/${loadId}/${phase}/abandon`, { method: 'PUT' });
        const data = await response.json();
        console.log('Abandon response:', data);
        return data;
    } catch (error) {
        console.error(`Error abandoning load ${loadId} in ${phase} phase`);
        alert(`Failed to abandon. Error ${error}.`);
        throw error;
    }
};

export const handleRetry = async (loadId: string, phase: string) => {
    try {
        const response = await fetch(`${BASE_URL}/loads/${loadId}/${phase}/retry`, { method: 'PUT' });
        const data = await response.json();
        console.log('Retry response:', data);
        return data;
    } catch (error) {
        console.error(`Error retrying load ${loadId} in ${phase} phase`);
        alert(`Failed to retry. Error ${error}.`);
        throw error;
    }
};